import { TFunction } from 'i18next';
import * as React from 'react';
import { CellProps } from 'react-table';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { ById } from '../../models/id';
import { Link } from '../../shared/navigation/Link';
import { HasOneOfPermissions } from '../../shared/Permissions';
import { getTrueOrFalseIcon } from '../../shared/StyledIcons';
import { useFiltering, UseFilteringResult } from '../../shared/tables/filtering/useFiltering';
import { PagedRequest } from '../../shared/tables/pagination/pagination';
import { CustomColumn } from '../../shared/tables/Table';
import { styled } from '../../styling/theme';
import { permissions } from '../authentication/permissions';
import { PracticeGroupResponse } from '../sites/practiceGroups/practiceGroup';
import { AccessibleSiteResponse } from '../sites/site';
import { UserFilterOptions, UserResponse, UsersResponse } from './user';
import { getUsers } from './usersApi';
import { editUserUrl } from './userUrls';

export const usersPageSize = 200;

export type UsersBaseProps = {
  sitesById: ById<AccessibleSiteResponse>;
  practiceGroupsById: ById<PracticeGroupResponse>;
};

const CellContainer = styled.div`
  min-width: 80px;
`;

const NameCellContainer = styled(CellContainer)`
  word-break: break-word;
`;

const EmailCellContainer = styled(CellContainer)`
  word-break: break-word;
`;

const NameCell = ({ cell }: CellProps<UserResponse>) => (
  <NameCellContainer>
    <HasOneOfPermissions
      permissions={[
        permissions.SuperAdmin,
        permissions.CentralUserAdmin,
        permissions.SiteUserAdmin,
      ]}
    >
      {hasPermission => {
        if (hasPermission) {
          return <Link to={editUserUrl(cell.row.original.id)}>{cell.value}</Link>;
        } else {
          return cell.value;
        }
      }}
    </HasOneOfPermissions>
  </NameCellContainer>
);

const EmailCell = ({ cell }: CellProps<UserResponse>) => (
  <EmailCellContainer>{cell.value}</EmailCellContainer>
);

const PermissionCell = ({ cell }: CellProps<UserResponse>) => (
  <CellContainer>{getTrueOrFalseIcon(cell.value)}</CellContainer>
);

export const getUserColumns = (t: TFunction): Array<CustomColumn<UserResponse>> => [
  {
    Header: t<string>('userList.nameColumnHeader'),
    accessor: row => row.name,
    id: 'userFullName',
    Cell: NameCell,
  },
  {
    Header: t<string>('userList.emailColumnHeader'),
    accessor: row => row.email,
    id: 'userEmail',
    Cell: EmailCell,
  },
  {
    Header: t<string>('userList.centralUserAdmin'),
    accessor: row => row.userPermissionCodes.includes(permissions.CentralUserAdmin),
    id: 'centralUserAdmin',
    Cell: PermissionCell,
  },
  {
    Header: t<string>('userList.centralAdmin'),
    accessor: row => row.userPermissionCodes.includes(permissions.CentralAdmin),
    id: 'centralAdmin',
    Cell: PermissionCell,
  },
  {
    Header: t<string>('userList.centralView'),
    accessor: row => row.userPermissionCodes.includes(permissions.CentralView),
    id: 'centralView',
    Cell: PermissionCell,
  },
  {
    Header: t<string>('userList.siteUserAdmin'),
    accessor: row => row.userPermissionCodes.includes(permissions.SiteUserAdmin),
    id: 'siteUserAdmin',
    Cell: PermissionCell,
  },
  {
    Header: t<string>('userList.sitePriceAdmin'),
    accessor: row => row.userPermissionCodes.includes(permissions.SitePriceAdmin),
    id: 'sitePriceAdmin',
    Cell: PermissionCell,
  },
  {
    Header: t<string>('userList.siteVisibilityAdmin'),
    accessor: row => row.userPermissionCodes.includes(permissions.SiteVisibilityAdmin),
    id: 'siteVisibilityAdmin',
    Cell: PermissionCell,
  },
  {
    Header: t<string>('userList.deviationBulkUploadAdmin'),
    accessor: row => row.userPermissionCodes.includes(permissions.DeviationBulkUploadAdmin),
    id: 'deviationBulkUploadAdmin',
    Cell: PermissionCell,
  },
  {
    Header: t<string>('userList.superAdmin'),
    accessor: row => row.userPermissionCodes.includes(permissions.SuperAdmin),
    id: 'superAdmin',
    Cell: PermissionCell,
  },
];

export const initialUserFilterOptions: UserFilterOptions = {
  searchText: null,
  siteIds: null,
  practiceGroupsIds: null,
  archived: false,
};

export const urlUsersToFilterUsers = (
  sitesById: ById<AccessibleSiteResponse>,
  practiceGroupsById: ById<PracticeGroupResponse>,
) => (urlState?: Partial<UserFilterOptions>): UserFilterOptions => {
  if (urlState === undefined) {
    return initialUserFilterOptions;
  }

  return {
    searchText: urlState.searchText ?? null,
    siteIds: urlState.siteIds?.filter(id => sitesById[id] != null) ?? null,
    practiceGroupsIds:
      urlState.practiceGroupsIds?.filter(id => practiceGroupsById[id] != null) ?? null,
    archived: urlState.archived ?? false,
  };
};

export const filterUsersToUrlUsers = (
  filterOptions?: UserFilterOptions,
): Partial<UserFilterOptions> => {
  if (filterOptions === undefined) {
    return {};
  }

  return {
    searchText: filterOptions.searchText ?? undefined,
    siteIds: filterOptions.siteIds ?? undefined,
    practiceGroupsIds: filterOptions.practiceGroupsIds ?? undefined,
    archived: filterOptions.archived ?? undefined,
  };
};

export type UseUserTableBaseStateResult = UseFilteringResult<
  UserFilterOptions,
  AuthenticatedApiRequest<UsersResponse, PagedRequest>
>;

export const useUserTableBaseState = (props: UsersBaseProps): UseUserTableBaseStateResult =>
  useFiltering({
    getData: getUsers,
    initialUrlState: filterUsersToUrlUsers(initialUserFilterOptions),
    urlStateToFilterOptions: urlUsersToFilterUsers(props.sitesById, props.practiceGroupsById),
    filterOptionsToUrlState: filterUsersToUrlUsers,
    pageName: 'users',
  });
